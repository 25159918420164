#menuPanelHeader{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #textContainer{
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  #itemLength{
    padding-left: 5px;
    padding-right: 5px;

  }
  .menuPanel{
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
    border-radius: 10px;

  }