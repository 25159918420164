@import '../../config/Colors.css';
.logo2 {
    height: 32px;
    margin: 16px;
    background: var(--sidebar-logo-background);
  }

.logoDesktopHeader {
  height: 70px;
  margin: 20px;
  background: var(--sidebar-logo-background);
  width: 150px;
}

.logoMobileHeader {
  height: 70px;
  background: var(--sidebar-logo-header);
  width: 150px;
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-items: c;
}
  
.site-layout .site-layout-background {
  background: var(--sidebar-logo-sitelayout-background);
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: var(--sidebar-logo-sitelayout-darktheme);
}

.headerDesktop {
  padding: 0;
  height: 'auto';
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';
}

.ouletNameCardDesktop {
  width: 400px;
  height: 75px;
  margin-top: 20px;
  text-align: center;
}
.ouletNameCardDesktop > div{
display: flex;
justify-content: space-around;
}

.ouletNameCard {
  height: 75px;
  text-align: center;
}

.ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 65px;
    right: -36px;
    z-index: 1;
    width: 36px;
    height: 42px;
    color: var(--sidebar-logo-sitelayout-background);
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    background: var(--outlet-ant-layout-sider-zero-width-trigger);
    border-radius: 0 2px 2px 0;
    cursor: pointer;
    transition: background 0.3s ease;
}

.mobileView {
  position: fixed;
  z-index: 99;
  height:100vh;
  width: 100%;
}

.ant-layout-sider .ant-layout-sider-zero-width-trigger-left{
    right: 164px;
    margin: auto;
    top: 66px;
    
}

.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger{
  top: 65px;
  right: -36px;
  margin:inherit;
}

.menuMobileView{
  position: relative;
  top: 53px;
}