@import '../config/Colors.css';
.login {
    width: 500px;
    margin: auto;
    background:var( --sidebar-logo-background);
    align-content: center;
    margin-top: 50px;
    border-radius: 100;
  }

.loginMobile {
  margin: auto;
  background:var( --sidebar-logo-background);
  align-content: center;
  align-items: center;
  margin-top: 50px;
  border-radius: 100;


}

#submit {
    align-self: start;
    width: 150px;
    border-radius: 10px;
}

.logoDesktop {
  height: 260px;
}

.logoDesktoplogin {
  height: 100px;
  width: 200px;
  margin-top: 0px;
}

.logoMobile {
  width: 302px;
  height: 170px;
}

.containerDiv {
  padding: 30px;
  background: var(--container-desktop);
  display: flex;
  justify-content: space-around;
}

.containerDivMobile {
  padding: 30px;
  background: var(--container-desktop);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}


.loginotp-wrapper{
  flex-direction: 'column' 
}

.loginotp-weblogo{
  margin-top: 10px;
  flex-direction: "row";
  display: 'flex';
  justify-content: 'space-around' 
}

.loginotp-weblogo-img{
  flex-direction: 'column';
  display: 'flex';
  justify-content: 'center'
}

.loginotp-weblogo-imgstyle{
  height: 50px; 
}

.loginotp-weblogo-irctcwrapper{
  flex-direction: 'column';
  display: 'flex';
  justify-content: 'center';
  margin-top: 8px
}

.loginotp-weblogo-p{
  font-family: 'cursive'
}


.loginotp-mobilelogo{
  margin: 10px;
  flex-direction: 'row';
  display: 'flex'; 
  justify-content: 'space-around'
}

.loginotp-mobilelogo-img{
  height: 50px 
}

.loginotp-mobilelogo-imgstyle{
  flex-direction: 'column';
  display: 'flex';
  justify-content: 'center';
  margin-top: 8px; 
}

.loginotp-mobilelogo-p{
  font-family: 'cursive'
}

.loginotp-card{
  border-width: 5px;
  border-radius: 5px;
}


.loginpass-wrapper{
  flex-direction: 'column';
}

.loginpass-weblogo{
  margin-top: 10px;
  flex-direction: "row";
  display: 'flex';
  justify-content: 'space-around'; 
}

.loginpass-weblogo-img{
  flex-direction: 'column';
  display: 'flex';
  justify-content: 'center'; 
}

.loginpass-weblogo-imgstyle{
  height: 50px;
}

.loginpass-weblogo-irctcwrapper{
  flex-direction: 'column';
  display: 'flex';
  justify-content: 'center';
  margin-top: 8px 
}

.loginpass-weblogo-p{
  font-family: 'cursive' 
}


.loginpass-mobilelogo{
  margin: 10px;
  flex-direction: "row";
  display: 'flex';
  justify-content: 'space-around';
}

.loginpass-mobilelogo-img{
  height: 50px
}

.loginpass-mobilelogo-imgstyle{
  flex-direction: 'column';
  display: 'flex';
  justify-content: 'center';
  margin-top: 8px; 
}

.loginpass-mobilelogo-irctc{
  font-family: 'cursive'
}


.loginpass-card{
  border-width: 5px;
  border-radius: 5px;
}

.home-logo{
  margin-top: 80px;
  width: 300px;
}

.text-rest{
  font-weight: 800;
  font-size: medium;
  text-align: center;
  width: 100%;
  margin-right: 140px;
  font-size: 20px;
  margin-bottom: 0px;
}

.homebutton-login{
  width: 60px;
  height: 30px;
  margin-top: 35px;
  border-radius: 11px;
}

.homelogin-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
}

.login-parent{
  display: flex;
  align-items: center;
}

.loginpass-irctclogo{
width: 113px;
}
.irctc-text{
  display: block;
  text-align: center;
  margin-top: 5px;
}

.loginpass-card{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginpass-card .ant-card-body{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-parentmobile{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-restmobile{
  font-weight: 800;
  font-size: medium;
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin-bottom: 0px;
}

.homebutton-loginmobile{
  width: 50px;
  height: 30px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
}


.loginpass-formitembutton{
  display: flex;
  justify-content: center;
  margin-left: 80px;
}

.loginpass-formitembuttonmobile{
  display: flex;
  justify-content: center;
  margin-left: 30px;
}

.loginotp-submitbuttonmobile{
 display: flex;
 justify-content: center;
 margin-left: 60px;
}

.loginotp-submitbuttonweb{
  display: flex;
  justify-content: center;
  margin-left: 100px;
}

.timer-text{
  text-align: right;
}

.timer-color{
  color: red;
}