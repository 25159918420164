.containerDesktop {
  padding: 30px;
  background: #ececec;
  display: flex;
  flex-direction: row;
}

.main-container {
  position: relative;
}

.mobile-container {
  background: #ececec;
}

.orderDetailsText {
  font-size: 17px;
  padding: 5;
}

.order-detail-sub-heading{
  font-size: 17px;
  padding: 5;
  color: gray;
  font-weight: bold;
}

.headerStyle {
  font-weight: bold;
}

.order-id{
  font-weight: bold;
}

.order-id-mobile{
  font-weight: bold;
  padding-left: 10px;
}

.order-summary-wrapper {
  margin-top: 20px;
  margin: 24px 16px;
  padding: 24px;
  min-height: 280;
  background: #ffffff;
}

.order-summary-wrapper-mobile {
  margin-top: 20px;
  min-height: 280;
  background: #ffffff;
}

.order-status-wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.order-status-wrapper-mobile {
  display: flex;
  padding: 5px;
  justify-content: space-between;
}

.order-status{
  border: 1px solid green;
  border-radius: 7px;
  padding: 5px;
  color: white;
  background-color: green;
}

.payment-type-mobile{
  color: green;
  margin-left: 10px;
  font-weight: bold;
  padding: 5px;
}

.order-details{
  padding: 30px;
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
}

.order-details-mobile{
  padding: 5px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.restaurant-details-wrapper{
  display: flex; 
  flex-direction: column
}

.detail-title{
  font-weight: bold
}

.customer-detail-wrapper{
  display: flex;
  flex-direction: column;
  text-align: end;
}

.customer-detail-wrapper-mobile{
  display: flex;
  flex-direction: column;
  /* text-align: end; */
}

.payment-type-wrapper{
  display: flex;
  flex-direction: column;
  text-align: end;
  padding-top: 10px;
  padding-bottom: 20px;
}

.payment-type{
  color: green;
  margin-left: 10px;
  font-weight: bold;
}

.card-details{
  height: 100%
}

.sub-card-details{
  margin-bottom: 10px
}

.sub-card-content{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.order-detail-headline{
  font-size: 30px;
  margin-bottom: 0px;
  padding-left: 50px;
  padding-top: 20px;
}
.order-detail-headline-mobile{
  font-size: 30px;
  margin-bottom: 0px;
  padding-left: 10px;
  padding-top: 20px;
}

.order-headline{
margin-left: 68px; 
font-size: 25px;
}

.order-headline-mobile{
  font-size: 25px;
  display: flex;
  justify-content: center;
  }

.modal-delivery-wrapper{
  display: flex; 
  flex-direction: row;
}

.modal-delivery-table{
  width: 300px;
}

.modal-delivery-card{
  margin-left: 30px
}

.table {
  margin: 10px;
}

.cardC1R1 {
  width: 300px;
  margin: 10px;
}

.cardC1 {
  width: 300px;
  margin: 10px
}

.cardC2R1 {
  width: 400px;
  margin: 10px;
}

.cardC2 {
  width: 400px;
  margin: 10px;
}

.cardMob {
  /* width: 250px; */
  margin: 10px;
}

.para {
  margin: 0em;
}

.unAuthHeadMobile{
  display: flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center
}

.unAuthHeadDesktop{
  display: flex;
  flex-direction:row;
  justify-content:space-around;
  align-items:center
}

.readStatusInside{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.readStatusInsideRole{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.item-des{
  width: 150px;
  word-break:break-word ;
  white-space: pre-wrap;
}