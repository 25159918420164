.menuUpload{
  text-align: end;
}

.descrip-web{
  text-align: center;
  font-size: 20px;
}

td.ant-descriptions-item {
  font-weight: bold;
  padding-top: 14px;
}

tr.ant-descriptions-row {
  border-bottom: 2px solid #bfbebe;
}


.img-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.ant-descriptions-outletdetails{
  border: 3px solid #c0c3d1;
  padding: 20px;
  margin-bottom: 22px;
}

.ant-descriptions-title{
  font-size: 20px;
}

span.ant-descriptions-item-label {
  font-weight: bold;
}

.vendor-asso{
  text-align: center;
}