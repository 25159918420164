@import '../../config/Colors.css' ;

.ant-collapse-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .ant-collapse-content-active {
    max-height: 1000px; /* Adjust the value to fit your content height */
  }

  .panel{
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
  }
  .past-eta-panel {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
    background-color: var(--grey)
  }
  
  .future-eta-panel {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
    background-color: var(--skyblue);
  }
  .panel-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  

  .ant-collapse {
    border: none;
  }
  
  .ant-collapse > .ant-collapse-item {
    border: none;
  }
  
  .ant-collapse-header {
    /* border-bottom: none; */
  }
  .textContainer{
    flex: 1;
    flex-direction: column;
  }
  .buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

.noorders-size{
  display: flex;
   align-items: center;
   justify-content: center;
   font-size: medium;
   flex-direction: column;
   width: 100%;
} 

.img-noorders{
  width: 60%;
}

.imgmobile-noorders{
  width: 320px;
}

.ordercust-details{
display: flex;
justify-content: space-between;
}

.no-order-container{
  display: flex;
  justify-content: center;
}

.total-bill{
  display: flex;
  justify-content: space-between;
}

.list-item{
  display: flex;
  flex-direction: column;
}

.order-orderbytrain-details{
  width: 100%;
  flex-direction: column;
}
.reject-order{
  width: 175px;
  margin-right: 10px;
  color: var(--white);
  background-color: var(--red);
}

.accept-order{
  width: 175px;
  margin-right: 10px;
  color: white;
  background-color: var(--kelly-green);
  border-color: var(--kelly-green);
}

.order-delivered-btn{
  width: 175px;
  margin-right: 10px;
  color: var(--white);
  background-color: var(--kelly-green);
  border-color: var(--kelly-green);
}

.order-undelivered-btn{
  width: 175px;
  margin-right: 10px;
  color: var(--white);
  background-color: var(--red);
}

.item-totalbill{
  margin-right: 100px;
}
#accept{
  display: inline-block;
  margin-right: 4px;

}


@media screen and (max-width:768px) {
  .item-totalbill{
    margin-right: 10px;
  }

  .button-wrapper{
    display: flex;
  }
}


.custom-itemName{
  width: 100px; 
}

.custom-itemNameWeb{
  width: 500px
}

.custom-quantity-column{
  width:3px;
}

.custom-price-column{
  width: 3px;
}

.status-preparing{
  width: 400px;
  height: 25px;
  color: var(--white);
  background-color: var(--kelly-green);
  font-weight: 700;
  display: inline-block;
  margin-right: 4px;
}

.status-cancelled{
  width: 400px;
  height: 25px;
  color: var(--white);
  background-color: var(--red);
  font-weight: 700;
}

.status-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.order-container{
  width: 100%;
  padding: 5px 5px 5px 5px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.ant-list-bordered .ant-list-item {
    padding-right: 20px;
    padding-left: 20px;
}

.order-length{
  height:30px;
  width: 30px;
  background-color: var(--red);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-text{
  margin-top: 7px;
}

.orders-placed-text{
  margin-top: 7px;
  color: var(--white);
}

.noordersmobile-text{
  text-align: center;
  font-weight: bold;
}

.noorder-webtext{
  text-align: center;
  font-weight: bold;
}


@media screen and (max-width:768px) {
  .ant-card.ant-card-bordered.ouletNameCard {
    display: flex;
    justify-content: center;
    align-items: center;
}
}