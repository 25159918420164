:root{
    --kelly-green: green;
    --magenta: magenta;
    --red:red;
    --white: white;
    --skyblue: skyblue;
    --grey:rgb(228, 224, 224);
    --container-desktop:  #ececec;
    --sidebar-logo-background: rgba(255, 255, 255, 0.3);
    --sidebar-logo-header:  rgba(255, 255, 255, 0.8);
    --sidebar-logo-sitelayout-background:  #fff;
    --sidebar-logo-sitelayout-darktheme:#141414;
    --timeline-mobilestatus-background:rgb(228 223 223);
    --timeline-mobileviewbutton: rgb(108, 189, 255);
    --timeline-mobilebutton-hover: rgba(144, 244, 245, 0.88);
    --outlet-namecard-div :#edebd9;
    --outlet-ant-layout-sider-zero-width-trigger:#001529;
}