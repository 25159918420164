@import '../../config/Colors.css';
.statusButton {
  display: flex;
  flex-direction: row;
  justify-Content:right;
}

.status-button-mobile{
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 20px;
  background: white;
  width: 100%;
  left: 0px;
  display: flex;
  justify-content: space-around;
}

.mobileViewButton {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--timeline-mobileviewbutton);
  align-items: center;
  padding: 25px 0px;
  font-weight: 600;
}

.mobileViewButton:hover {
  background-color: var(--timeline-mobilebutton-hover);
}

.order-status-button {
  margin-right: 10px;
  border-radius: 5px;
  width: 170px;
  font-size: 15px;
}


.item-container {
  display: flex;
  align-items: center;
}

.item-name {
  margin-right: 8px; /* Add any desired spacing between item name and quantity */
}

.quantity-container {
  display: flex;
  align-items: center;
}

.box-one {
   font-weight: 'bold';
}

.quantity {
  margin: 0 8px; /* Add any desired spacing between PlusSquareFilled, quantity, and MinusSquareFilled */
}