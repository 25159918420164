@import '../../config/Colors.css';
.container {
    background: var(--container-desktop);
    height: 50vh;
    align-items: center;
    display: flex;
    justify-content: center;

}

.logo{
  height: 85px;
  width: 200px;
}

.containerMobile {
  background: var(--container-desktop);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}

.outletCardDesktop {
  width: 440px;
}

.selectWidthDesktop {
  width: 300px;
}

.selectWidthMobile {
  width: 160px;
}

.wrapper{
  height: 100vh;
  background: var(--container-desktop);
}
.outlet-wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
}


.outlet-wrapper-mobile-card{
   display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-right: 20px; */
}

.ant-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgb(0 0 0 / 85%);
    font-weight: 500;
    font-size: 20px;
    border-bottom: 2px solid #ffffff;
    border-radius: 2px 2px 0 0;
}