.selectDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px
}
.ant-collapse-header {
    margin-top: 20px;
}

.itemLength{
   float: right;
  }